/* General styles */
/* body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
  } */
  
  /* Section */
  .login-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffffff3b;
  }
  
  /* Container */
  .login-container {
    text-align: center;
  }
  
  /* Heading */
  .login-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Login Box */
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 450px;
  }
  
  /* Form container */
  .login-form-container {
    padding: 10px;
  }
  
  /* Login Title */
  .login-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Input Group */
  .custome-input-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  /* Label */
  .input-label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    /* border-radius: 10px; */
    display: block;
  }
  
  /* Input Field */
  .custome-input-field {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccccccc4;
    border-top-left-radius: 3px;
    border-radius: 0.5rem;
    /* color: #ffffff0a; */
    outline: none;
  }
  
  .custome-input-field:focus {
    border-color: #007bff;
  }
  
  /* Login Button */
  .login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #00838F;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #006064;
  }
  
  /* Register Text */
  .register-text {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  /* Register Link */
  .register-link {
    font-weight: bold;
    color:#0097A7;
    text-decoration: none;
  }
  
  .register-link:hover {
    text-decoration: underline;
  }
  
  .small-text{
    font-size: 12px; /* Reduce text size */
    color: red; /* Make it stand out */
    margin-top: 4px; /* Add space from input field */
  }